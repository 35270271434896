<template>
  <v-card>
  <validation-observer ref="observer">
    <v-card-text>
      <v-container>
        <v-row class="py-4">
          <v-col cols="12" class="text-center text-heading-1">
            写真管理
            <div>
              <v-btn
                depressed
                class="close-button"
                fab
                color="#E27C7C"
                width="20"
                height="20"
                @click="$emit('close-dialog')"
              >
                <v-icon size="14">mdi-close</v-icon>
              </v-btn>
            </div>
          </v-col>

          <v-col cols="12" class="text-center pb-0">
            <input
              type="file"
              ref="file"
              style="display: none"
              accept="image/png, image/gif, image/jpeg"
              @change="onFilePicked"
            />

            <img
              :src="preview_file"
              width="100%"
              height="300"
              class="image_preview mb-2"
            />
            <!-- <v-btn height="200" block depressed @click="$refs.file.click()">
              <v-icon v-if="!preview_file" size="180">$imagePlaceholder</v-icon>
            </v-btn> -->
          </v-col>

          <v-col cols="12">
            <div class="d-flex justify-space-between">
              <span style="color: #AD4545" class="text-center mr-3" @click="removePhoto()">
                - 写真削除
              </span>
              <span
                style="color: #336ABB"
                class="text-center"
                @click="$refs.file.click()"
              >
                + 画像アップロード
              </span>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <div class="form-header mb-2">
              実施日
            </div>
            <div>
              <v-dialog
                ref="dialogBirthDay"
                v-model="modal.date"
                :return-value.sync="data.date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <validation-provider 
                     v-slot="{errors}"
                     name="photoDate"
                     rules="required">
                  <v-text-field
                    v-model="data.date"
                    readonly
                    class="form-text"
                    v-bind="attrs"
                    v-on="on"
                    dense
                    :error-messages="errors"
                    :error="errors.length !== 0"
                    hide-details="auto"
                    outlined
                    placeholder="日付選択"
                  ></v-text-field>
                   </validation-provider>
                </template>
                <v-date-picker
                  v-model="data.date"
                  locale="ja"
                  :day-format="formatDatePicker"
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal.date = false">
                    キャンセル
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialogBirthDay.save(data.date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </div>
          </v-col>
          <v-col cols="12">
            <div class="form-header mb-2">
              説明文
            </div>
            <div>
            <validation-provider
                  v-slot="{ errors }"
                  name="photoDescription"
                  rules="required"
                >
              <v-textarea
                class="form-text"
                dense
                outlined
                placeholder="画像の説明文"
                hide-details="auto"
                :error-messages="errors"
                :error="errors.length !== 0"
                v-model="data.memo"
              ></v-textarea>
              </validation-provider>
              <div class="text-right">
                {{ data.memo.length }} / {{ descriptionMaxLength }} 文字
              </div>
            </div>
          </v-col>

          <v-col cols="12">
            <v-row align="center">
              <v-col cols="6">
                <v-btn @click="remove" color="#AD4545" text>
                  削除する
                </v-btn>
              </v-col>
              <v-col cols="6" class="text-right">
                <v-col cols="auto">
                  <v-btn
                    @click="save"
                    color="#4F55A7"
                    class="white--text"
                    width="100"
                    :loading="loading"
                    >更新する
                  </v-btn>
                </v-col>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    </validation-observer>
  </v-card>
</template>

<script>
import dayjs from "dayjs";
export default {
  name: "EditPhotoDialog",
  data() {
    return {
      descriptionMaxLength: 500,
      modal: {
        date: false
      },
      preview: null,
      loading: false,
      file: null
    };
  },
  props: {
    customer: {
      type: Object,
      required: true
    },
    photo: {
      type: Object,
      required: true
    },
    site_id: {
      type: Number,
      required: true
    }
  },
  computed: {
    preview_file: {
      get() {
        if (this.preview) return this.preview;

        return this.data.path
          ? this.data.path_url
          : `${process.env.VUE_APP_ROOT_API}images/no-image.jpg`;
      },
      set(value) {
        this.preview = value;
      }
    },
    data: {
      get() {
        return this.photo;
      },
      set(newValue) {
        this.$emit("update:photo", newValue);
      }
    }
  },
  methods: {
    save() {
       this.$refs.observer.validate().then(success => {
        if (!success) {
          return;
        }
      this.loading = true;
      let id = this.data.id;
      let data = new FormData();
      data.append("customer_id", this.customer.id);
      data.append("site_id", this.customer.id);
      data.append("_method", "PUT");
      for (const [key, value] of Object.entries(this.data)) {
        if(key !== 'photo')
          data.append(key, value ? value : value == 0 ? 0 : "");
      }

      if(this.file)
        data.append("photo", this.file);

      this.$store
        .dispatch("PHOTO_UPDATE", { id, data })
        .then(
          response => {
            if (response.data.status == "success") {
              this.getPhotoData();
              this.$emit("close-dialog");
            }
          },
          error => {
            throw error;
          }
        )
        .finally(() => (this.loading = false));
        })
    },
    removePhoto() {
      this.data.photo = null
      this.data.path = null
      this.data.remove_photo = true
      this.file = null
      this.preview = null
    },
    remove() {
      this.$store.dispatch("PHOTO_DELETE", this.data.id).then(
        response => {
          if (response.data.status == "success") {
            this.getPhotoData();
            this.$emit("close-dialog");
          }
        },
        error => {
          throw error;
        }
      );
    },
    getPhotoData() {
      let param = {
        id: this.customer.id,
        customer_id: this.customer.id,
        site_id: this.site_id
      };
      this.$store.dispatch("PHOTO_GET_ALL_BY_CUSTOMER_ID", param);
    },
    formatDatePicker(e) {
      return dayjs(e).format("D");
    },
    onFilePicked(e) {
      this.file = e.target.files[0];
      this.preview_file = URL.createObjectURL(this.file);
    }
  }
};
</script>

<style scoped>
.close-button {
  position: absolute;
  right: 20px;
  top: 20px;
  color: white;
}

.form-header {
  color: #828282;
}

.form-text {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
}
.image_preview {
  object-fit: cover;
}
</style>
